import '../css/GridItemFilm.css'
import { useNavigate } from "react-router-dom";
import NoFilmImage from '../images/generic-profile.jpg'

function GridItemPerson({item }) {
  return (
    <div className="item item position-relative">
      <FilmImage item={item} />
      <PersonJob item={item} />
      <PersonName item={item} />
    </div>
  )
}

// <PersonInfo item={item}/>

function FilmImage({item }) {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/${item.dType}/${item.nconst}`);

  let src
  const hasImage = item.image
  if(!hasImage) src = NoFilmImage// 'https://upload.wikimedia.org/wikipedia/commons/c/c2/No_image_poster.png'
  if(hasImage) {
    const replacement = '_V1_QL75_UY281_CR4,0,190,281_'
    src = item.image.replace('_V1_', replacement)
  }
  
  return (
    <div className='clickable'>
      <img className="img-resp" src={src} alt="film poster" onClick={handleClick}/>
    </div>
  )
}

function PersonJob({item}) {
  if(item.role && item.role.includes('_')) item.role = item.role.split('_').join(' ')
  return (
    <div className='position-absolute top-0 image-text w-100 text-wrap text-center'>
      <p className='text-capitalize m-0'>{item.role}</p>
    </div>
  )
}

function PersonName({item}) {
  return (
    <div className='position-absolute bottom-0 image-text w-100 text-wrap text-center'>
      <p className='text-capitalize m-0'>{item.name}</p>
    </div>
  )
}

function PersonInfo({item}) {
  const characters = item.characters.join(',')
  const charactersText = characters.length === 0 ? '' : <p className='fw-bold m-0 image-text'>{characters}</p>
  return (
      <div className="h-auto bg-dark d-flex flex-column justify-content-between text-wrap text-center">
        {charactersText}
        <p className='m-0 image-text'>{item.name}</p>
      </div>
  )
}

export default GridItemPerson