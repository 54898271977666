import './css/colors.css'
import './css/index.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import App from './App';
import AppMain from './AppMain'
import Films from './pages/Films'
import ShortList from './pages/ShortList';
import Search from './pages/Search'
import About from './pages/About';
import Contact from './pages/Contact';
import Film from './pages/Film';
import Person from './pages/Person';

const root = ReactDOM.createRoot(document.getElementById('root'));
const dataUrl = 'https://gist.githubusercontent.com/wasim-build/e3c2ca46838dfc1e3de17a2ca8bf77ae/raw' // 
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App url={dataUrl}/>}>
          <Route path='/' element={<AppMain/>}>
            <Route path='/' element={<Films/>} />
            <Route path='/search' element={<Search/>} />
            <Route path='/shortlist' element={<ShortList/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/film/*' element={<Film/>}/>
            <Route path='/person/*' element={<Person/>}/>
            <Route path='*' element={<div>Oops!!</div>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

function DefaultPage() {
  return <div>Default Page</div>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
