function getHeaders() {
  return [
    {
      name: 'title',
      type: 'string',
      classes: 'text-start',
      getVal: item => item.title.trim().toUpperCase(),
      showVal: item => item.title
    },
    {
      name: 'year',
      type: 'number',
      classes: 'text-center',
      getVal: item => item.imdb.startYear,
      showVal: item => {
        const text = (item.imdb.startYear < 0) ? 'Unknown' : item.imdb.startYear
        return <span className="badge bg-light text-dark">{text}</span>
      }
    },
    {
      name: 'duration',
      type: 'number',
      classes: 'text-center',
      getVal: item => item.imdb.runtimeMinutes,
      showVal: item => {
        if(item.imdb.runtimeMinutes < 0) return <i className="fa-regular fa-circle-xmark"></i>
        const minutes = item.imdb.runtimeMinutes % 60
        const hours = Math.floor(item.imdb.runtimeMinutes / 60)
        return `${hours}h${minutes}m`
      }
    },
    {
      name: 'votes',
      type: 'number',
      classes: 'text-end',
      getVal: item => item.imdb.rating.numVotes,
      showVal: item => {
        if(item.imdb.rating.numVotes < 0) return <i className="fa-regular fa-circle-xmark"></i>
        return item.imdb.rating.numVotes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    {
      name: 'rating',
      type: 'number',
      classes: 'text-center',
      getVal: item => item.imdb.rating.averageRating,
      showVal: item => {
        let score = item.imdb.rating.averageRating.toFixed(1)
        if(!item.imdbId) return <i className="fa-regular fa-circle-xmark"></i>
        if(item.imdb.rating.averageRating < 0) score = 'N/A'
        const badge = <span className="badge imdb">{score}</span>
        const href = `https://www.imdb.com/title/${item.imdbId}`
        return <a target="_blank" rel="noreferrer" href={href}>{badge}</a>
      }
    },
    {
      name: 'rating+',
      type: 'number',
      classes: 'text-center',
      getVal: item => item.imdb.rating.altRating,
      showVal: item => {
        if(item.imdb.rating.altRating < 0) makeBadge(item.imdb.rating.altRating, 'N/A')
        return makeBadge(item.imdb.rating.altRating, `${item.imdb.rating.altRating.toFixed(2)}%`)
      }
    },
    {
      name: 'channel',
      type: 'string',
      classes: 'text-center',
      getVal: item => item.channel.name,
      showVal: item => {
        const channel = item.channel.name.toUpperCase()
        const badge = <span className="badge bg-secondary text-white">{channel}</span>
        return <a target="_blank" rel="noreferrer" href={item.channel.location}>{badge}</a>
      }
    }
  ]
}

function makeBadge(score, str) {
  const allColors = ["#d7e1ee", "#cbd6e4", "#bfcbdb", "#b3bfd1", "#a4a2a8", "#df8879", "#c86558", "#b04238", "#991f17"]
  let bgColor = null
  if(score < 25) bgColor = allColors[0]
  if(score >= 25) bgColor = allColors[1]
  if(score >= 40) bgColor = allColors[2]
  if(score >= 50) bgColor = allColors[3]
  if(score >= 60) bgColor = allColors[4]
  if(score >= 70) bgColor = allColors[5]
  if(score >= 80) bgColor = allColors[6]
  if(score >= 90) bgColor = allColors[7]
  if(score >= 95) bgColor = allColors[8]
  const style = { backgroundColor: bgColor}
  if(score < 0) str = 'N/A'
  return <span className="badge" style={style}>{str}</span>
}

export default getHeaders