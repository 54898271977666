export default function getDefaultUserFilters() {
  return {
    decades : [],
    fTypes: [],
    genres: [],
    durations: null,
    votes: null,
    ratings: null,
    ratingsAlt: null
  }
}