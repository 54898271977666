import '../../css/FilmsSettings.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import FilmNamesSwitch from './FilmNamesSwitch';
import FilmsBarSwitch from './FilmsBarSwitch';
import PaginatorSwitch from './PaginatorSwitch';

function FilmsSettings({ show, handleClose, data }) {
  const { current, set } = data

  return (
    <Offcanvas placement='start' show={show} onHide={handleClose} backdrop={false}>
      <Offcanvas.Header className='films-settings-header' closeButton>
        <Offcanvas.Title>Settings</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='films-settings-body'>
        <FilmNamesSwitch value={current.showFilmName} set={set}/>
        <FilmsBarSwitch value={current.stickyFilmsBar} set={set}/>
        <PaginatorSwitch value={current.fixedPaginator} set={set}/>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default FilmsSettings