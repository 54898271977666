import '../css/AppNav.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useHref, useNavigate } from 'react-router-dom'

function AppNav() {
  const navigate = useNavigate();

  const navLinks = [
    { name: "Search", href: "/search"},
    { name: "Short List", href: "/shortlist"},
    { name: "Films", href: "/"},
    { name: "About", href: "/about"},
    { name: "Contact", href: "/contact"}
  ]

  const currentHref = useHref()

  const makeLink = (name, href, i) => {
    let className = 'main-nav-link'
    if(currentHref === href) className = className.concat(' main-nav-link-active')
    return <Link  key={i} className={className} to={href}>{name}</Link>
  }

  return (
    <Navbar className='app-nav' collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand className='clickable'>
          <span onClick={() => navigate('/')}><i className="fa-solid fa-film"></i> Free Films UK</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          </Nav>
          <Nav>
            {navLinks.map((n, i) => makeLink(n.name, n.href, i))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default  AppNav;