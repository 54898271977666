import '../css/About.css'
import { useState } from "react";
import PageHeader from '../comps/PageHeader';
import General from '../comps/about/General';
import Features from "../comps/about/Features";

function About() {

  const [key, setKey] = useState('general');
  let content
  if(key === 'general') content = <General/>
  if(key === 'features') content = <Features/>

  return (
    <div id="about" className='bg-cover flex-container flex-content'>
      <PageHeader title='About'/>
      <Tabs options={['general','features']} tKey={key} setTKey={setKey}/>
      <div className='fade-in container flex-container flex-content'>
        {content}
      </div>
    </div>
  )
}

function Tabs({ options,tKey, setTKey }) {
  const tabs = options.map((e, i) => {
    const isActive = e === tKey ? "active" : ""
    return (
      <li key={i} className="nav-item clickable" onClick={() => setTKey(e)}>
        <a className={"nav-link ".concat(isActive)}><span className='text-capitalize'>{e}</span></a>
      </li>)
  })
  return <ul className="film-tabs nav nav-tabs">{tabs}</ul>
}

export default About;