export default function getFilmRatings() {
  return [
    { name: '> 6', value: 6 },
    { name: '> 6.5', value: 6.5 },
    { name: '> 7', value: 7 },
    { name: '> 7.5', value: 7.5 },
    { name: '> 8', value: 8 },
    { name: '> 8.5', value: 8.5 },
    { name: '> 9', value: 9 }
  ]
}