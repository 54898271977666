import Button from 'react-bootstrap/Button';

function MultiSelect({label, propName, current, setCurrent, choices}) {

  const handleAllClick = () => {
    if(current.length > 0) {
      setCurrent(prevState => {
        const updatedValues = { [propName] : []}
        return {...prevState, ...updatedValues};
      });
    }
  }
  
  const handleClick = e => {
    const foundCurrent = choices.find(d => d.name === e.target.id)
    const isActive = current.find(c => c.name === e.target.id)
    if(!isActive) {
      const arr = current.concat(foundCurrent)
      setCurrent(prevState => {
        const updatedValues = { [propName] : arr}
        return {...prevState, ...updatedValues};
      });
    } else {
      const arr = []
      current.forEach( c => {
        if(c.name !== e.target.id) arr.push(c)
      })
      setCurrent(prevState => {
        const updatedValues = { [propName] : arr}
        return {...prevState, ...updatedValues};
      });
    }
  }

  const allButton = ['All'].map((name, i) => {
    const variant = current.length === 0 ? "success" : "outline-light"
    return <Button id={name} variant={variant} key={i} className='m-1' size="sm" onClick={handleAllClick}>{name}</Button>
  })

  const buttons = choices.map((choice, i) => {
    const isActive = current.find(d => d.name === choice.name)
    const variant = isActive ? "success" : "outline-light"
    const displayName = choice.displayName || choice.name.replace('_', '')
    return <Button id={choice.name} variant={variant} key={i+1} className='m-1' size="sm" onClick={handleClick}>{displayName}</Button>
  })
  return (
    <div>
      <span><b>{label}</b></span>
      {allButton}
      {buttons}
    </div>
  )
}

export default MultiSelect