export default function getFilmGenres(films) {
  const genres = []
  films.forEach(film => {
    film.imdb.genres.forEach( genre => {
      if(!genres.includes(genre)) genres.push(genre)
    })
  })
  return genres.map(genre => {
    return { name: genre }
  })
}