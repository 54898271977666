export default function orderFilms(data, headers, order) {

  const sortStrings = (getValFunc, data, order) => {
    return data.sort((a, b) => {
      const aVal = getValFunc(a)
      const bVal = getValFunc(b)
      const vals = order.direction === 'des' ? [aVal, bVal] : [bVal, aVal]
      if (vals[0] > vals[1]) return -1
      if (vals[0] < vals[1]) return 1
      return 0
    })
  }

  const sortNumbers = (getValFunc, data, order) => {
    return data.sort((a, b) => {
      const aVal = getValFunc(a)
      const bVal = getValFunc(b)
      const vals = order.direction === 'des' ? [aVal, bVal] : [bVal, aVal]
      return vals[1] - vals[0]
    })
  }

  const header = headers.find(h => h.name === order.name)
  if(header.type === 'string') return sortStrings(header.getVal, data, order)
  if(header.type === 'number') return sortNumbers(header.getVal, data, order)
}