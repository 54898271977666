import Form from 'react-bootstrap/Form';

function FilmNamesSwitch({value, set}) {
  
  const handleClick = e => {
    set(prevState => {
      const updatedValues = { showFilmName : !value}
      return {...prevState, ...updatedValues};
    });
  }
  return (
    <Form>
      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Film Names"
        onChange={handleClick}
        checked={value}
      />
      <Form.Text>
        Show the film title as well as the film image.
      </Form.Text>
    </Form>
  );
}

export default FilmNamesSwitch