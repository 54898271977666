export default function getDefaultUserSettingss() {
  return {
    showFilmName: false,
    order: { 
      name: 'rating+', 
      direction: 'des'
    },
    maxPageItems: 18,
    stickyFilmsBar: true,
    fixedPaginator: true
  }
}