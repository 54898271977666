import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function OrderSelector({ order, set, headers}) {
  const [isOpen, setOpen] = useState(false)

  const handleOpenClick = () => {
    setOpen(!isOpen)
  }

  const handleChange = e => {
    console.log(e.target.value)
    set(prevState => {
      const updatedValues = { order: {name: e.target.value, direction: 'des'}}
      return {...prevState, ...updatedValues};
    });
  }

  const handleDirectionClick = () => {
    const newDirection = order.direction === 'des' ? 'asc' : 'des'
    set(prevState => {
      const updatedValues = { order: { name: order.name, direction: newDirection}}
      return {...prevState, ...updatedValues};
    });
  }

  const options = headers.map((header, i) => {
    if(order.name === header.name) return <option key={i+1} value={header.name}>{header.name}</option>
    return <option key={i+1} value={header.name}>{header.name}</option>
  })

  const Select = isOpen ? (<Form.Select aria-label="order setter" size="sm" value={order.name} onChange={handleChange}>
    {options}
  </Form.Select>) : ''

  const header = headers.find(header =>  header.name === order.name)
  const directionIcon = getDirectionIcon(header, order.direction)
  const Direction = isOpen ? (
    <Button variant="outline-light" className='m-1' size="sm" onClick={handleDirectionClick}>{directionIcon}</Button>
  ): ''
  return (
    <div className='d-flex'>
      <Button variant="outline-light" className='m-1' size="sm" onClick={handleOpenClick}><i className="fa-solid fa-sort"></i></Button>
      {Select}
      {Direction}
    </div>
  )
}

function getDirectionIcon(header, direction) {
  if(header.type === 'string') {
    return direction === 'asc' ? <i className="fa-solid fa-arrow-down-a-z"></i> : <i className="fa-solid fa-arrow-down-z-a"></i>
  }
  if(header.type === 'number') {
    return direction === 'asc' ? <i className="fa-solid fa-arrow-down-1-9"></i> : <i className="fa-solid fa-arrow-down-9-1"></i>
  }
} 

export default OrderSelector