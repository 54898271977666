export default function getFilmDurations() {
  return [
    { name: '< 4', value: 240 },
    { name: '< 3.5', value: 210 },
    { name: '< 3', value: 180 },
    { name: '< 2.5', value: 150 },
    { name: '< 2', value: 120 },
    { name: '< 1.5', value: 90 },
    { name: '< 1', value: 60 },
    { name: '< 0.5', value: 30 }
  ]
}