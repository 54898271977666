import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import FilmsInfo from './FilmsInfo';
import FilmsSettings from './FilmsSettings';
import FilmsFilters from './FilmsFilters';
import OrderSelector from './OrderSelector';
import getDefaultUserFilters from '../../funcs/defaults/filters';

function FilmsOptions({ info, settings, filters, headers }) {

  const [showInfo, setShowInfo] = useState(false)
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);

  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);

  const [showFilters, setShowFilters] = useState(false);
  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);

  const handleClickSweep = () => filters.set(getDefaultUserFilters())

  const stickyTop = settings.current.stickyFilmsBar ? "sticky-top" : ""

  return (
    <Navbar className={stickyTop} expand="lg" variant="dark" bg="dark">
      <Container>
        <div className='d-flex'>
          <Button onClick={handleShowInfo} variant="outline-light" className='m-1' size="sm"><i className="fa-solid fa-circle-info"></i></Button>
          <Button onClick={handleShowSettings} variant="outline-light" className='m-1' size="sm"><i className="fa-solid fa-sliders"></i></Button>
          <OrderSelector order={settings.current.order} set={settings.set} headers={headers} />
        </div>
        <div className='d-flex'>
          <Button onClick={handleShowFilters} variant="outline-light" className='m-1' size="sm"><i className="fa-solid fa-filter"></i></Button>
          <Button onClick={handleClickSweep} variant="outline-light" className='m-1' size="sm"><i className="fa-solid fa-broom"></i></Button>
        </div>
      </Container>
      <FilmsInfo show={showInfo} handleClose={handleCloseInfo} data={info} />
      <FilmsSettings show={showSettings} handleClose={handleCloseSettings} data={settings}/>
      <FilmsFilters show={showFilters} handleClose={handleCloseFilters} data={filters} />
    </Navbar>
  )
}

export default FilmsOptions