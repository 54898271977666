import '../css/PageHeader.css'

function PageHeader({title, description}) {
  const p = description ? <p className="text-center">{description}</p> : ''
  return (
    <div className='page-header'>
      <h2 className="text-center">{title}</h2>
      {p}
    </div>
  )
}

export default PageHeader