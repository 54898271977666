import '../css/Film.css'
import { useState } from "react";
import { useHref, useOutletContext } from 'react-router-dom'
import NoFilmImage from '../images/no_image_poster.png'
import Grid from '../comps/Grid'
import PageHeader from '../comps/PageHeader';
import scrollToTop from '../funcs/scrollToTop';

function Film() {
  scrollToTop()
  const [key, setKey] = useState('all');
  const [ context ] = useOutletContext()
  const { people } = context
  const films = context.films.all
  const headers = context.films.headers
  const currentHref = useHref()
  const id = getId(currentHref)
  const film = films.find(f => f.id === id)
  const principals = film.imdb.principals.map(principal => {
    const person = people.find(p => p.nconst === principal[0])
    person.role = principal[1]
    person.characters = principal[3] || []
    return person
  })

  const actors = principals.filter(principal => principal.role === 'actor' || principal.role === 'actress')
  const others = principals.filter(principal => principal.role !== 'actor' && principal.role !== 'actress')

  let content
  if(key === 'all') content = <Info film={film} headers={headers}/>
  if(key === 'actors') content = actors.length !== 0 ? <Grid items={actors} /> : <NoData/>
  if(key === 'crew') content = others.length !== 0 ? <Grid items={others} /> : <NoData/>

  return (
    <div className='bg-cover flex-container flex-content'>
      <PageHeader title={film.title}/>
      <Tabs options={['all','actors','crew']} tKey={key} setTKey={setKey}/>
      {content}
    </div>
  )
}

function Tabs({ options,tKey, setTKey }) {
  const tabs = options.map((e, i) => {
    const isActive = e === tKey ? "active" : ""
    return (
      <li key={i} className="nav-item clickable" onClick={() => setTKey(e)}>
        <a className={"nav-link ".concat(isActive)}><span className='text-capitalize'>{e}</span></a>
      </li>)
  })
  return <ul className="film-tabs nav nav-tabs">{tabs}</ul>
}

function Info({film, headers}) {
  return (
    <div className='fade-in flex-content flex-container container'>
      <div className='flex-content d-flex flex-column justify-content-around'>
        <div className="row">
          <FilmImage film={film}/>
          <FilmInfo film={film} headers={headers}/>
        </div>
        <FilmLinks imdbId={film.imdbId} location={film.channel.location}/>
      </div>
    </div>
  )
}

function FilmImage({film}) {
  let src
  const hasImage = film.imdb && film.imdb.image
  if(!hasImage) src = NoFilmImage// 'https://upload.wikimedia.org/wikipedia/commons/c/c2/No_image_poster.png'
  if(hasImage) {
    const replacement = '_V1_QL75_UY281_CR4,0,190,281_'
    src = film.imdb.image.replace('_V1_', replacement)
  }

  return (
    <div className="col-sm">
      <div className='d-flex h-100 justify-content-center align-items-center p-2'>
        <img className="img-fluid img-border" src={src} alt=""/>
      </div>
    </div>
  )
}

function FilmInfo({film, headers}) {
  const {title, description, imdb, channel, imdbId} = film
  const {startYear, runtimeMinutes, genres, rating } = imdb
  const { location} = channel
  const {numVotes, averageRating, altRating} = rating

  const imdbScoreHeader = headers.find( h => h.name === 'rating')
  const altScoreHeader = headers.find( h => h.name === 'rating+')
  const votesHeader = headers.find( h => h.name === 'votes')
  const channelHeader = headers.find( h => h.name === 'channel')
  const yearHeader = headers.find(h => h.name === 'year')

  const getYear = num => yearHeader.showVal(film)
  const getGenres = genres => (genres.length === 0) ? 'Unavailable' : genres.join(', ')
  const getDuration = duration => (duration < 0) ? 'Unknown' : `${duration} minutes`
  const getChannel = () => channelHeader.showVal(film)
  const getScore = num => (num < 0) ? 'Unavailable' : imdbScoreHeader.showVal(film)
  const getScoreAlt = num => (num < 0) ? 'Unavailable' : altScoreHeader.showVal(film)
  const getVotes = num => (num < 0) ? 'Unavailable' : votesHeader.showVal(film)
  const cleanDescription = description ? getCleanDescription(description) : 'Description Unavailable'

  return (
    <div className="col-sm">
      <div><p className="lead text-white p-2">{cleanDescription}</p></div>
      
      <div id="film-info-general" className='film-info'>
        <h3 className='film-info-header p-2'>General</h3>
        <dl className="row p-2">
          <dt className="col-6">Release Year:</dt>
          <dd className="col-6">{getYear(startYear)}</dd>
          <dt className="col-6">Duration:</dt>
          <dd className="col-6" >{getDuration(runtimeMinutes)}</dd>
          <dt className="col-6">Genres:</dt>
          <dd className="col-6" >{getGenres(genres)}</dd>
          <dt className="col-6">Broadcaster:</dt>
          <dd className="col-6" >{getChannel()}</dd>
        </dl>
      </div>

      <div id="film-info-imdb" className='film-info'>
        <h3 className='film-info-header p-2'>IMDb</h3>
        <dl className="row p-2">
          <dt className="col-6">Votes:</dt>
          <dd className="col-6">{getVotes(numVotes)}</dd>
          <dt className="col-6">Rating:</dt>
          <dd className="col-6" >{getScore(averageRating)}</dd>
          <dt className="col-6">Rating Plus:</dt>
          <dd className="col-6" >{getScoreAlt(altRating)}</dd>
        </dl>
      </div>
    </div>
  )
}

function FilmLinks({ imdbId, location }) {
  const imdbHref = `https://www.imdb.com/title/${imdbId}`
  const imdbButton = imdbId ? <button className='btn imdb'><a className='linkButton text-dark' target="_blank" rel="noreferrer" href={imdbHref}>Visit IMDb Page</a></button> : ''
  return (
    <div className='film-links d-flex justify-content-around p-2'>
      <button className='btn bg-darkgrey'>
        <a className='linkButton text-dark' target="_blank" rel="noreferrer" href={location}>Watch Film</a>
      </button>
      {imdbButton}
    </div>
  )
}

function NoData() {
  return (
    <div className='flex-container flex-content justify-content-center'>
      <h3 className='text-center text-white'>No Data Available</h3>
    </div>
  )
}

function getId(str) {
  const startIndex = str.indexOf('/', 1)
  if(startIndex < 0) return null
  const slice = str.slice(startIndex + 1)
  return decodeURI(slice)
}

function getCleanDescription(description) {
  // remove channel 4 year
  if(!description.startsWith('(')) return description
  const sliceEnd = description.indexOf(')')
  if(sliceEnd < 0) return description
  return description.slice(sliceEnd + 1)
}

export default Film