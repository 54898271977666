import PageHeader from '../comps/PageHeader';
import PageInfoItem from '../comps/PageInfoItem';

function Contact() {
  return (
    <div id="contact" className='bg-cover flex-container flex-content'>
      <PageHeader title='Contact'/>
      <div className='fade-in container flex-container flex-content'>
        <div className="flex-container flex-content  justify-content-around">
          <PageInfoItem title='Feedback' body={<Suggestions/>}/>
          <PageInfoItem title='How' body={<ContactMethods/>}/>
          <PageInfoItem title='Support' body={<Coffee/>}/>
        </div>
      </div>
    </div>
  )
}

function Suggestions() {
  return (
    <div>
      <p>I would greatly appreciate any feedback you would like to provide. Especially, if you spot:</p>
      <ul>
        <li>any errors <i className="fa-solid fa-circle-exclamation"></i>,</li>
        <li>encounter any bugs <i className="fa-solid fa-bugs"></i>, or</li>
        <li>would like to see a new feature <i className="fa-regular fa-lightbulb"></i></li>
      </ul>
    </div>
  )
}

function ContactMethods() {
  return (
    <div>
      <p>Contact me by sending:</p>
      <ul>
        <li>an email to <a className='hyperlink' target="_blank" rel="noreferrer" href="mailto:wasimj.dev@gmail.com"><i className="fa-regular fa-envelope"></i></a>, or</li>
        <li>a direct message on <a className='hyperlink' target="_blank" rel="noreferrer" href="https://twitter.com/wa51mj"><i className="fa-brands fa-twitter"></i></a></li>
      </ul>
    </div>
  )
}

function Coffee() {
  return (
    <div>
      <p>This web app <u>does not require money</u> for existing costs or future development.</p>
      <p>However, if you have found the application useful and would like to buy me a <i className="fa-solid fa-mug-hot"></i> you can do so <a className='hyperlink' target="_blank" rel="noreferrer" href="https://www.buymeacoffee.com/wasimj">here</a>.</p>
    </div>
  )
}

export default Contact;