export default function getFilmRatingsAlt() {
  return [
    { name: '> 60%', value: 60 },
    { name: '> 65%', value: 65 },
    { name: '> 70%', value: 70 },
    { name: '> 75%', value: 75 },
    { name: '> 80%', value: 80 },
    { name: '> 85%', value: 85 },
    { name: '> 90%', value: 90 }
  ]
}