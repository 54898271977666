export default function getFilmDecades() {
  return [
    {
      name: '1930s',
      range: [1930, 1939]
    },
    {
      name: '1940s',
      range: [1940, 1949]
    },
    {
      name: '1950s',
      range: [1950, 1959]
    },
    {
      name: '1960s',
      range: [1960, 1969]
    },
    {
      name: '1970s',
      range: [1970, 1979]
    },
    {
      name: '1980s',
      range: [1980, 1989]
    },
    {
      name: '1990s',
      range: [1990, 1999]
    },
    {
      name: '2000s',
      range: [2000, 2009]
    },
    {
      name: '2010s',
      range: [2010, 2019]
    },
    {
      name: '2020s',
      range: [2020, 2029]
    }
  ]
}