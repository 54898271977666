import getFilmDecades from "./getFilmDecades";
import getFilmDurations from "./getFilmDurations";
import getFilmGenres from "./getFilmGenres";
import getFilmRatings from "./getFilmRatings";
import getFilmRatingsAlt from "./getFilmRatingsAlt";
import getFilmTypes from "./getFilmTypes";
import getFilmVotes from "./getFilmVotes";

export default function getFilterOptions(films) {
  return {
    decades: getFilmDecades(),
    fTypes: getFilmTypes(films),
    genres: getFilmGenres(films),
    durations: getFilmDurations(),
    votes: getFilmVotes(),
    ratings: getFilmRatings(),
    ratingsAlt: getFilmRatingsAlt()
  }
}