import '../css/Films.css'
import { useOutletContext } from 'react-router-dom'
import FilmsOptions from '../comps/FilmsBar/FilmsOptions';
import FilmsList from '../comps/FilmsList';

function Films() {
  const [ context ] = useOutletContext()
  const { films, shortList } = context
  const { total, current, page, info, settings, filters, headers } = films

  const description = 'These films are free to watch'

  const areFiltersActive = currentFiltersActive(filters.current)
  const filterUpdate = areFiltersActive ? `${total} Films (Filters Are Active)` : `${total} Films`
  const filmsInfo = <div className='films-info w-100 text-center'>{filterUpdate}</div>
  const filmsList = <FilmsList items={current} shortList={shortList} page={page} headers={headers} settings={settings}/>

  return (
    <div id="films" className='bg-cover flex-container flex-content'>
      <FilmsOptions info={info} settings={settings} filters={filters} headers={headers}/>
      { filmsInfo }
      { total === 0 ? <NoMatches/> : filmsList} 
    </div>
  )
}

function currentFiltersActive(currentFilters) {
  for (const prop in currentFilters) {
    const currentFilter = currentFilters[prop]
    if(Array.isArray(currentFilter) && currentFilter.length > 0) return true
    if(!Array.isArray(currentFilter) && currentFilter) return true
  }
  return false
}

function NoMatches() {
  return (
    <div className='flex-container flex-content justify-content-center'>
      <div className='text-center text-white m-auto p-2'>
        <h3>No Films Found </h3>
        <p>
          Adjusting your filter options by clicking <i className="fa-solid fa-filter"></i>
          <br/>
          Or quickly reset your filter choices by clicking <i className="fa-solid fa-broom"></i>
        </p>
      </div>
    </div>
  )
}

export default Films