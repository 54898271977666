import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PageHeader from '../comps/PageHeader'
import FilmsList from '../comps/FilmsList';
import getPageItems from '../funcs/getPageItems';

export default function ShortList() {
  const [ context ] = useOutletContext();
  const settings = context.films.settings
  const headers = context.films.headers
  const shortList = context.shortList
  const films = context.films.data

  const results = shortList.current.map(id => films.find(f => id === f.id))

  let contents
  if(results.length === 0 ) {
    contents = <ShortListEmpty/>
  } else {
    contents = <ShortListResults items={results} headers={headers} settings={settings} shortList={shortList}/>
  }
  return (
    <div className='bg-cover flex-container flex-content'>
      <PageHeader title="Short List"/>
      {contents}
    </div>
  )
}

function ShortListResults({ items, headers, settings, shortList }) {
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, items)
  const totalPages = Math.ceil(items.length/itemsPerPage)
  const pageInfo = {current: page, set:setPage, max: totalPages}

  return (
    <div>
      <FilmsList items={gridItems} page={pageInfo} headers={headers} settings={settings} shortList={shortList}/>
    </div>
  )
}

function ShortListEmpty() {
  return (
    <div className='flex-container flex-content justify-content-center'>
      <div className='fade-in text-center text-white m-auto p-2'>
        <h3>No Films Short Listed</h3>
        <p>
          Find films to short list by clicking on "Search".
          <br/>
          Or to see all available films click on "Films" .</p>
      </div>
    </div>
  )
}