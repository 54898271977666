export default function getFilmVotes() {
  return [
    { name: '> 100', value: 100 },
    { name: '> 1,000', value: 1000 },
    { name: '> 5,000', value: 5000 },
    { name: '> 10,000', value: 10000 },
    { name: '> 50,000', value: 50000 },
    { name: '> 100,000', value: 100000 },
    { name: '> 500,000', value: 500000 }
  ]
}