import '../css/GridItemFilm.css'
import { useNavigate } from "react-router-dom";
import NoFilmImage from '../images/no_image_poster.png'

function GridItemFilm({item, shortList, headers, settings}) {
  const toggleShortList = shortList ? <ToogleShortList item={item}  shortList={shortList}/> : ''
  const { showFilmName } = settings.current
  return (
    <div className="item position-relative placeholder">
      <FilmImage item={item} showFilmName={showFilmName}/>
      <Scores item={item} headers={headers}/>
      <MovieLink item={item} shortList={shortList} headers={headers}/>
      { toggleShortList }
    </div>
  )
}

function FilmImage({item, showFilmName}) {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/${item.dType}/${item.id}`);

  let src
  const hasImage = item.imdb && item.imdb.image
  if(!hasImage) src = NoFilmImage// 'https://upload.wikimedia.org/wikipedia/commons/c/c2/No_image_poster.png'
  if(hasImage) {
    const replacement = '_V1_QL75_UY281_CR4,0,190,281_'
    src = item.imdb.image.replace('_V1_', replacement)
  }

  const titleText = !hasImage || showFilmName ? <ImageTitle title={item.title} /> : ''
  
  return (
    <div className="position-relative clickable">
      <img className="img-resp" src={src} alt="film poster" onClick={handleClick}/>
      {titleText}
    </div>
  )
}

function ImageTitle({ title }) {
  const style = {top: '35%'}
  return (
    <p className='image-text position-absolute w-100 text-wrap text-center' style={style}>
      {title}
    </p>
  )
}


function Scores({item, headers}) {
  const imdbScoreHeader = headers.find( h => h.name === 'rating')
  const altScoreHeader = headers.find( h => h.name === 'rating+')
  // const hasRating = imdbScoreHeader.getVal(item) > 0
  const hasRatingPlus = imdbScoreHeader.getVal(item) > 0
  const scoreHtml = item.imdbId ? <span>{imdbScoreHeader.showVal(item)}</span> : ''
  const altScoreHtml = hasRatingPlus ? <span>{altScoreHeader.showVal(item)}</span> : ''
  return (
    <div className="w-100 d-flex justify-content-around position-absolute bottom-0 p-1">
      {scoreHtml}
      {altScoreHtml}
    </div>
  )
}

function ToogleShortList({item, shortList}) {
  const itemShortListIndex = shortList.current.indexOf(item.id)
  const handleClick = () => {
    if(itemShortListIndex > -1) {
      const newShortlist = shortList.current.filter(si => si !== item.id)
      shortList.set(newShortlist)
    } else {
      const newShortlist = shortList.current.concat(item.id)
      shortList.set(newShortlist)
    } 
  }
  const solidStar = <i className="fa-solid fa-star fa-xl"></i>
  const emptyStar = <i className="fa-regular fa-star fa-xl"></i>
  const star = itemShortListIndex > -1 ? solidStar : emptyStar
  
  return <span className='gold position-absolute top-0 start-0 m-1 clickable' onClick={handleClick}>{star}</span>
}

function MovieLink({item, shortList, headers}) {
  const channelHeader = headers.find( h => h.name === 'channel')
  const channel = channelHeader.showVal(item)
  return (
    <div className='position-absolute top-0 end-0 m-1'>
      {channel}
    </div>
  )
}
/*
<div className='w-100 d-flex justify-content-end position-absolute top-0 p-1'>
      {channel}
    </div>
*/

export default GridItemFilm