import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './comps/appLoading/Loader';
import Fail from './comps/appLoading/Fail';
import fetchData from './funcs/fetchData';

function App({ url }) {
  let contents = null
  const [data, setData] = useState({ status: 'fetching', d: false })
  if(data.status === 'fetching') contents = <Loader />
  if(data.status === 'failure') contents = <Fail />

  // EFFECTS
  useEffect(() => { fetchData(url, setData) }, [url]) // get data

  return (
    <div id="app" className="flex-container min-vh-100">
      {contents || <Outlet context={[data]}/>}
    </div>
  );
}

export default App;
