import '../../css/FilmsInfo.css'
import Modal from 'react-bootstrap/Modal';

function FilmsInfo({ show, handleClose, data }) {
  const { buildDate, filmsTotal } = data
  const dateObj = new Date(buildDate)
 
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className='films-info-header' closeButton>
        <Modal.Title>Data Information</Modal.Title>
      </Modal.Header>
      <Modal.Body className='films-info-body'>
        <dl className="row">
          <dt className="col-3 text-center"><i className="fa-solid fa-film"></i></dt>
          <dd className="col-9">{filmsTotal} films</dd>
          <dt className="col-3 text-center"><i className="fa-solid fa-calendar-days"></i></dt>
          <dd className="col-9">{getNiceDateString(dateObj)}</dd>
          <dt className="col-3 text-center"><i className="fa-solid fa-clock"></i></dt>
          <dd className="col-9">{getTimeString(dateObj)}</dd>
        </dl>
      </Modal.Body>
    </Modal>
  )
}

function getNiceDateString(d) {
  return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear();
}

function getTimeString(d) {
  return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
}

export default FilmsInfo