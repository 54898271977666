import '../css/Search.css'
import React, { useState, useEffect, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import PageHeader from '../comps/PageHeader';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';
import Button from 'react-bootstrap/Button';

import FilmsList from '../comps/FilmsList';

function Search() {
  const [ context ] = useOutletContext();
  const headers = context.films.headers
  const settings = context.films.settings
  const films = context.films.data
  const people = context.people
  const data = { films, people }
  const search = context.search
  const shortList = context.shortList
  
  const options = ['all', 'title', 'person']
  const [option, setOption] = useState(options[0])
  // const [text, setText] = useState('')
  const text = search.text.current
  const setText = search.text.set
  const [results, setResults] = useState(null)
  const description = 'Search for a film title, or person (lead actor, director, producer, etc).'
  const textInfo = { text, setText}
  const optionsInfo = { option, setOption, options}

  useEffect(() => { 
    if(text.length < 3) setResults(null)
    if(text.length >= 3) {
      const results = searcher({ text, option, data})
      setResults(results)
    } 
  }, [text, option])

  let contents
  if(results) {
    if(results.length === 0 ) {
      contents = <NoMatches/>
    } else {
      contents = <SearchResults items={results} headers={headers} settings={settings} shortList={shortList}/>
    }
  } else {
    contents = ''
  }

  const minCharacters = 3
  const needInputHelp = text.length > 0 && text.length < 3
  const charactersNeeded = minCharacters - text.length
  const inputHelp = needInputHelp ? (<p className='text-center text-white'>
  Keep on typing, we need {charactersNeeded} more character{charactersNeeded > 1 ? 's': ''} for the search.
</p>) : ''
  
  return (
    <div id="search" className='bg-cover flex-container flex-content'>
      <PageHeader title='Search' description={description}/>
      <div className='flex-container flex-content'>
        <div className='container mt-3'>
          <SearchBar textInfo={textInfo} optionsInfo={optionsInfo}/>
        </div>
        {inputHelp}
        {contents}
      </div>
    </div>
  )
}

function SearchBar({ textInfo, optionsInfo}) {
  const { text, setText} = textInfo
  const { setOption, options} = optionsInfo
  
  const optionsHtml = options.map((e, i) => <option key={i}>{e}</option>)

  const handleSelectChange = e => setOption(e.target.value)
  const handleSearchChange = e => setText(e.target.value)

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text className='search-icon'>
        <i className="fas fa-search"></i>
      </InputGroup.Text>
      <Form.Control ref={inputRef} type="text" placeholder="Search" value={text} onChange={handleSearchChange}/>
      
      <Form.Group>
        <Form.Select className='search-icon' onChange={handleSelectChange}>
          {optionsHtml}
        </Form.Select>
      </Form.Group>
      
      
    </InputGroup>
  )
}
/*
<InputGroup.Text>
  <i onClick={() => setText('')} className="fa-solid fa-broom"></i>
</InputGroup.Text>
*/

function SearchResults({ items, headers, settings, shortList }) {
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, items)
  const totalPages = Math.ceil(items.length/itemsPerPage)
  const pageInfo = {current: page, set:setPage, max: totalPages}

  const paginator = items.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={items.length}/> : ''
  return (
    <div>
      <FilmsList items={gridItems} page={pageInfo} headers={headers} settings={settings} shortList={shortList}/>
    </div>
  )
}
//       <Grid />
// { paginator }

function searcher({ text, option, data}) {
  const { films, people } = data
  const arr = []
  // films
  for (const film of films) {
    if(option === 'all' || option === 'title') {
      const includesText = film.title.toUpperCase().includes(text.toUpperCase())
      if(includesText) arr.push(film)
    }
  }
  // people
  for (const person of people) {
    if(option === 'all' || option === 'person') {
      const includesText = person.name.toUpperCase().includes(text.toUpperCase())
      if(includesText) arr.push(person)
    }
  }
  return arr
}

function NoMatches() {
  return (
    <div className='flex-container flex-content justify-content-center'>
      <h3 className='text-center text-white'>No Matches</h3>
    </div>
  )
}

export default Search