import { useHref, useOutletContext } from 'react-router-dom'
import Grid from '../comps/Grid'
import PageHeader from '../comps/PageHeader'

function Person() {
  const [ context ] = useOutletContext()
  const { people } = context
  const headers = context.films.headers
  const settings = context.films.settings
  const shortList = context.shortList
  const currentHref = useHref()
  const id = getId(currentHref)
  const person = people.find(p => p.nconst === id)
  const films = context.films.all
  const personInFilms = films.filter(film => {
    const { principals } = film.imdb
    if(principals.length === 0) return false
    for (const principal of principals) {
      if(principal[0] === person.nconst) return true
    }
    return false
  })

  const items = [{image: person.image, nconst: person.nconst}].concat(personInFilms)

  return (
    <div className='bg-cover flex-container flex-content'>
      <PageHeader title={person.name}/>
      <Grid items={items} settings={context.films.settings} headers={context.films.headers} shortList={shortList}/>
    </div>
  )
}

function getId(str) {
  const startIndex = str.indexOf('/', 1)
  if(startIndex < 0) return null
  const slice = str.slice(startIndex + 1)
  return decodeURI(slice)
}

export default Person