import { useState, useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom'
import AppNav from './comps/AppNav';
import getPageItems from './funcs/getPageItems';
import getHeaders from './funcs/getHeaders';
import getFilterOptions from './funcs/filterOptions/index';
import getDefaultUserFilters from './funcs/defaults/filters';
import getDefaultUserSettingss from './funcs/defaults/settings';
import orderFilms from './funcs/orderFilms';
import filterFilms from './funcs/filterFilms';

function AppMain() {
  const [ data ] = useOutletContext();

  const films = data.d.films.map(film => {
    film.dType = 'film'
    return film
  })
  const people = data.d.people.map(person => {
    person.dType = 'person'
    return person
  })

  const [searchText, setSearchText] = useState('')
  const [shortList, setShortList] = useState(getStoredShortList())
  const [filteredSearch, setFilteredSearch] = useState([])
  const [pageNumSearch, setPageNumSearch] = useState(1)
  
  const [filteredFilms, setFilteredFilms] = useState(films)
  const [pageNumFilms, setPageNumFilms] = useState(1)

  const [userFilters, setUserFilters] = useState(getDefaultUserFilters())
  const [userSettings, setUserSettings] = useState(getDefaultUserSettingss())

  const filterOptions = getFilterOptions(films)

  useEffect(() => {
    const result = filterFilms(films, userFilters)
    setFilteredFilms(result)
  }, [userFilters])

  useEffect(() => {
    localStorage.setItem("shortList", JSON.stringify(shortList));
  }, [shortList])

  const getMaxPages = (total, maxPageItems) => Math.ceil(total/maxPageItems)
  const { maxPageItems } = userSettings
  const orderedFilms = orderFilms(filteredFilms, getHeaders(), userSettings.order)

  const context = {
    search: {
      current: filteredSearch,
      page: {
        current: pageNumSearch,
        set: setPageNumSearch
      },
      text: {
        current: searchText,
        set: setSearchText
      }
    },
    shortList: {
      current: shortList,
      set: setShortList
    },
    films: {
      data: films,
      all: filteredFilms,
      total: filteredFilms.length,
      current: getPageItems(pageNumFilms, maxPageItems, orderedFilms),
      page: {
        current: pageNumFilms,
        set: setPageNumFilms,
        max: getMaxPages(filteredFilms.length, maxPageItems),
        maxPageItems
      },
      filters: {
        current: userFilters,
        set: setUserFilters,
        options: filterOptions
      },
      settings: {
        current: userSettings,
        set: setUserSettings
      },
      info: {
        buildDate: data.d.buildDate,
        filmsTotal: films.length
      },
      headers: getHeaders()
    },
    people
  }

  return (
    <main id="app-main "className='fade-in flex-container flex-content'>
      <AppNav/>
      <Outlet context={[context]}/>
    </main>
  )
}

function getStoredShortList() {
  const saved = localStorage.getItem("shortList");
  const initialValue = JSON.parse(saved)
  return initialValue || [];
}

export default AppMain;