import '../css/Paginator.css'
import scrollToTop from '../funcs/scrollToTop'

function Paginator({pageInfo, settings}) {
  const { current, set, max } = pageInfo
  const pageFirst = 1
  const pagePrevious = current === 1 ? 1 : (current - 1)
  const pageNext = current + 1
  const pageLast = max

  const fixedBottom = settings && settings.current && settings.current.fixedPaginator ? "fixed-bottom" : ""
  const className = `paginator d-flex justify-content-center ${fixedBottom}`

  return (
    <nav id="pagination-list" className={className}>
      <ul className="pagination m-0">
        <FirstPage num={pageFirst} page={current} setPage={set}/>
        <PreviousPage num={pagePrevious} page={current} setPage={set}/>
        <CurrentPage num={current}/>
        <NextPage num={pageNext} page={current} setPage={set} total={max}/>
        <LastPage num={pageLast} page={current} setPage={set} total={max}/>
      </ul>
    </nav>
  )
}

function FirstPage({num, page, setPage}) {
  const handleClick = () => {
    if(page > 1) {
      setPage(1)
      scrollToTop()
    }
  }
  return (
    <li id="pag-first" className="page-item" disabled={page <= num} onClick={handleClick}>
      <span className="page-link"  aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </span>
    </li>
  )
}

function PreviousPage({num, page, setPage}) {
  const handleClick = () => {
    if(page > 1) {
      setPage(page - 1)
      scrollToTop()
    }
  }
  const isDisabled = page <= num
  const display = isDisabled ? '...' : num
  return (
    <li id="pag-previous" className="page-item" disabled={isDisabled} onClick={handleClick}>
      <span className="page-link" >{display}</span>
    </li>
  )
}

function CurrentPage({num}) {
  return (
    <li className="page-item active">
      <span className="page-link" >{num}</span>
    </li>
  )
}

function NextPage({num, page, setPage, total}) {
  const handleClick = () => {
    if(page < total) {
      setPage(page + 1)
      scrollToTop()
    }
  }
  const isDisabled = page >= total
  const display = isDisabled ? '...' : num
  return (
    <li id="pag-next" className="page-item" disabled={isDisabled} onClick={handleClick}>
      <span className="page-link" >{display}</span>
    </li>
  )
}

function LastPage({num, page, setPage, total}) {
  const handleClick = () => {
    if(page < total) {
      setPage(total)
      scrollToTop()
    }
  }
  return (
    <li id="pag-last" className="page-item" disabled={page >= total} onClick={handleClick}>
      <span className="page-link"  aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </span>
    </li>
  )
}

export default Paginator