import Button from 'react-bootstrap/Button';

function SingleSelect({label, propName, current, setCurrent, choices}) {

  const handleAllClick = () => {
    if(current) {
      setCurrent(prevState => {
        const updatedValues = { [propName] : null}
        return {...prevState, ...updatedValues};
      });
    }
  }
  
  const handleClick = e => {
    const foundCurrent = choices.find(choice => choice.name === e.target.id)
    const isActive = current && current.name === e.target.id
    const valueToSet =  !isActive ? foundCurrent : null
    setCurrent(prevState => {
      const updatedValues = { [propName] : valueToSet}
      return {...prevState, ...updatedValues};
    });
  }

  const allButton = ['All'].map((name, i) => {
    const variant = !current ? "success" : "outline-light"
    return <Button id={name} variant={variant} key={i} className='m-1' size="sm" onClick={handleAllClick}>{name}</Button>
  })

  const buttons = choices.map((choice, i) => {
    const isActive = current && current.name === choice.name
    const variant = isActive ? "success" : "outline-light"
    return <Button id={choice.name} variant={variant} key={i+1} className='m-1' size="sm" onClick={handleClick}>{choice.name}</Button>
  })
  return (
    <div>
      <span><b>{label}</b></span>
      {allButton}
      {buttons}
    </div>
  )
}

export default SingleSelect