import PageInfoItem from '../PageInfoItem';

export default function Features () {
  return (
    <div className="flex-container flex-content justify-content-around">
      <p className='text-white'>Over time additional features have been made available to help when selecting a film. These are described below:</p>
      <PageInfoItem title='rating+' body={<AltRating/>}/>
      <PageInfoItem title='Options Bar' body={<FilmPageOptionsBar/>}/>
      <PageInfoItem title='Grid Swipe' body={<SwipeFeature/>}/>
      <PageInfoItem title='Search' body={<SearchDetails/>}/>
    </div>
  )
}

function AltRating() {
  return (
    <div>
      <p>Each listed film which has an IMDb score also contains a rating+ score.</p>
      <p>The rating+ score is calculated using a fairly simple formula. This formula takes into account not only the IMDb score but also the number of votes cast. This is so that films with low votes but high ratings are penalised. The following two cases should make the point clearer:</p>
      <ul>
        <li>a film which only has 18 votes and has an IMDb rating of 8.8 will have a rating+ score of 80.05%</li>
        <li>a film which has 498,167 votes and has an IMDb rating of 8.2 will have a rating+ score of 81.69%</li>
      </ul>
      <p>The second film has a higher rating+ score than the first film despite having a lower IMDb score.</p>
      <p>The implemented formula is adapted from an algorithm used by the website SteamDb. To read more about SteamDb's algorithm, click <a className='hyperlink' target="_blank" rel="noreferrer" href="https://steamdb.info/blog/steamdb-rating/">here</a>.</p>
    </div>
  )
}

function FilmPageOptionsBar () {
  const list = [
    {
      icon:  <i className="fa-solid fa-circle-info"></i>,
      title: "Data Information",
      text: ["Shows information about the films data."]
    },
    {
      icon:  <i className="fa-solid fa-sliders"></i>,
      title: "Settings",
      text: ["Opens a side panel where minor settings such as displaying a film's title can be turned on or off."]
    },
    {
      icon:  <i className="fa-solid fa-sort"></i>,
      title: "Order",
      text: ["Allows films to reordered in many ways such as by title, year, duration, votes, etc."]
    },
    {
      icon:  <i className="fa-solid fa-filter"></i>,
      title: "Filter",
      text: ["Opens a side panel which offers numerous ways to filter the films that are displayed."]
    },
    {
      icon:  <i className="fa-solid fa-broom"></i>,
      title: "Filter Quick Reset",
      text: ["Click here when you want to quickly reset the filters"]
    }
  ]
  const optionsList = list.map((e,i) => {
    const {icon, title, text} = e
    const paragraphs = text.map((p,i) => <p key={i}>{p}</p>)
    return (
      <div key={i} className="row">
        <div className="col-sm-4">
          <p>{icon} - {title}</p>
        </div>
        <div className="col-sm-8">
          {paragraphs}
        </div>
      </div>
    )
  })

  return (
    <div>
      <p>Above the films grid on the films page there is a white bar. This contains numerous clickable buttons which allow visitors to change how and what films are shown. These are describe below:</p>
      {optionsList}
    </div>
  )
}

function SwipeFeature() {
  return (
    <div>
      <p>On touch screens, when both a grid of items and the paginator is present a user can:</p>
      <ul>
        <li>swipe left to turn to the next page, or</li>
        <li>swipe right to turn to a previous page</li>
      </ul>
    </div>
  )
}

function SearchDetails () {
  return (
    <div>
      <p>Click on the "Search" section to search for a film title, or person (lead actor, director, producer, etc). All search items are clickable.</p>
    </div>
  )
}