import '../../css/FilmsFilters.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import MultiSelect from '../select/MultiSelect';
import SingleSelect from '../select/SingleSelect';

function FilmsFilters({ show, handleClose, data }) {
  const { current, set, options } = data
  const { decades, fTypes, genres, durations, votes, ratings, ratingsAlt } = options

  return (
    <Offcanvas placement='end' show={show} onHide={handleClose} backdrop={false}>
      <Offcanvas.Header className='films-filters-header' closeButton>
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='films-filters-body'>
        <MultiSelect label='Decades:' propName='decades' current={current.decades} setCurrent={set} choices={decades}/>
        <hr/>
        <MultiSelect label='Genres:' propName='genres' current={current.genres} setCurrent={set} choices={genres}/>
        <hr/>
        <SingleSelect label='Votes:' propName='votes' current={current.votes} setCurrent={set} choices={votes}/>
        <hr/>
        <SingleSelect label='Rating:' propName='ratings' current={current.ratings} setCurrent={set} choices={ratings}/>
        <hr/>
        <SingleSelect label='Rating+:' propName='ratingsAlt' current={current.ratingsAlt} setCurrent={set} choices={ratingsAlt}/>
        <hr/>
        <SingleSelect label='Duration:' propName='durations' current={current.durations} setCurrent={set} choices={durations}/>
        <hr/>
        <MultiSelect label='Type:' propName='fTypes' current={current.fTypes} setCurrent={set} choices={fTypes}/>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default FilmsFilters