import logo from '../../images/film-reel.svg'
import '../../css/Loader.css'

function Fail() {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Spinner/>
    </div>
  )
}

function Spinner() {
  return (
    <div  className="text-center">
      <img src={logo} className="loader-img" alt="logo" />
      <p className="pt-2 text-white glow">
        Sorry, we were unable to fetch the app data.<br/>
        Please try again later.</p>
    </div>
  )
}

export default Fail