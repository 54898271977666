import logo from '../../images/film-reel.svg'
import '../../css/Loader.css'

function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Spinner/>
    </div>
  )
}

function Spinner() {
  return (
    <div className="container text-center">
      <img src={logo} className="loader-img App-logo" alt="logo" />
      <p className="pt-2 text-white">Loading Data</p>
    </div>
  )
}

export default Loader