import { useState } from 'react';
import Paginator from "./Paginator"
import scrollToTop from '../funcs/scrollToTop'
import Grid from './Grid';

function FilmsList({ items, page, headers, settings, shortList }) {

  const needsPaginator =  page.max > 1
  const paginator = needsPaginator ? <Paginator pageInfo={page} settings={settings}/> : ''

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // SWIPE
  const minSwipeDistance = 50; // the required distance between touchStart and touchEnd to be detected as a swipe

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe && page.current > 1) {
      page.set(page.current - 1)
      scrollToTop()
    }
    if (isLeftSwipe && page.current < page.max) {
      page.set(page.current + 1)
      scrollToTop()
    }
  }

  return (
    <div className="flex-container flex-content" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className='flex-content d-flex flex-column justify-content-between'>
        <Grid items={items} headers={headers} settings={settings} shortList={shortList}/>
        { paginator }
      </div>
    </div>
  )
}

export default FilmsList