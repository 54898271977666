export default function filterFilms( films, options) {
  const { decades, fTypes, genres, durations, votes, ratings, ratingsAlt } = options

  let filteredFilms = films

  if(decades.length > 0) filteredFilms = filterDecades(filteredFilms, decades) // decades
  if(fTypes.length > 0) filteredFilms = filterTypes(filteredFilms, fTypes) // types
  if(genres.length > 0) filteredFilms = filterGenres(filteredFilms, genres) // genres
  if(durations) filteredFilms = filteredFilms.filter(film => film.imdb.runtimeMinutes < durations.value ) // durations
  if(votes) filteredFilms = filteredFilms.filter(film => film.imdb.rating.numVotes > votes.value ) // votes
  if(ratings) filteredFilms = filteredFilms.filter(film => film.imdb.rating.averageRating > ratings.value ) // ratingImdb
  if(ratingsAlt) filteredFilms = filteredFilms.filter(film => film.imdb.rating.altRating > ratingsAlt.value ) // ratingPlus

  return filteredFilms
}

function filterDecades(filteredFilms, decades) {
  return filteredFilms.filter(film => {
    const {startYear} = film.imdb
    let isOk = false
    for (const decade of decades) {
      const [min, max] = decade.range
      if(startYear >= min && startYear <= max) {
        isOk = true
        break
      }
    }
    return isOk
  })
}

function filterTypes(filteredFilms, types) {
  return filteredFilms.filter(film => {
    const {titleType} = film.imdb
    let isOk = false
    for (const filmType of types) {
      if(titleType === filmType.name) {
        isOk = true
        break
      }
    }
    return isOk
  })
}

function filterGenres(filteredFilms, genres) {
  return filteredFilms.filter(film => {
    const genresFilm = film.imdb.genres
    let isOk = false
    for (const genre of genres) {
      if(genresFilm.includes(genre.name)) {
        isOk = true
        break
      }
    }
    return isOk
  })
}
