import '../css/Grid.css'
import React, { useState } from 'react'
import GridItemPicture from './GridItemPicture';
import GridItemFilm from './GridItemFilm';
import GridItemPerson from './GridItemPerson';
import scrollToTop from '../funcs/scrollToTop';

function Grid({ items, headers, settings, shortList }) {
  const itemsHtml = items.map((e, i) => {
    if(!e.dType) return <GridItemPicture key={i} item={e} />
    if(e.dType === 'person') return <GridItemPerson key={i} item={e} />
    if(e.dType === 'film') return <GridItemFilm key={i} item={e} headers={headers} settings={settings} shortList={shortList}/>
  })
  return (
    <div id="grid" className="fade-in container mt-3 mb-5">
      <div className="grid-wrapper">{itemsHtml}</div>
    </div>
  )
}

export default Grid
