import '../css/GridItemFilm.css'
import { useNavigate } from "react-router-dom";
import NoFilmImage from '../images/generic-profile.jpg'

function GridItemPicture({item }) {
  return (
    <div className="item position-relative">
      <FilmImage item={item} />
      <ImdbNConstLink item={item}/>
    </div>
  )
}

function FilmImage({item }) {

  let src
  const hasImage = item.image
  if(!hasImage) src = NoFilmImage// 'https://upload.wikimedia.org/wikipedia/commons/c/c2/No_image_poster.png'
  if(hasImage) {
    const replacement = '_V1_QL75_UY281_CR4,0,190,281_'
    src = item.image.replace('_V1_', replacement)
  }
  
  return (
    <div>
      <img className="img-resp" src={src} alt="film poster" />
    </div>
  )
}
function ImdbNConstLink({item}) {
  const badge = <span className='badge imdb'>IMDb Page</span>
  const href = `https://www.imdb.com/name/${item.nconst}/`
  const link = <a target="_blank" rel="noreferrer" href={href}>{badge}</a>
  return (
    <div className="scores d-flex justify-content-center w-100 position-absolute bottom-0 p-1">
      {link}
    </div>
  )
}

export default GridItemPicture