import PageInfoItem from '../PageInfoItem';

export default function General () {
  return (
    <div className="flex-container flex-content  justify-content-around">
      <PageInfoItem title='Background' body={<Background/>}/>
      <PageInfoItem title='Sharing' body={<Sharing/>}/>
      <PageInfoItem title='Data Policy' body={<DataPolicy/>}/>
    </div>
  )
}

function Background() {
  return (
    <div>
      <p>Every friday night I like to watch a good film. However, I face two problems:</p>
      <ol>
        <li><u>subscriptions!</u> - subscription services, like Netlix, have some great content, but all I really want is to watch a good film once a week. I can't justify paying for a service that I will hardly use.</li>
        <li><u>which movie should I choose to watch?</u> - there a lot of films (hundreds!) that are free to watch. However, It can be easy to waste time sifting through what is available.</li>
      </ol>
      <p>I created this small web app to help!</p>
      <p>It deals with the first problem by listing films that can be streamed, both <b>freely and legally</b>, by anyone living in the UK. At present, the films listed are compiled from:</p>
      <ul>
        <li><a className="about-channel-link" href="https://www.bbc.co.uk/iplayer">BBC iPlayer</a> (BBC)</li>
        <li><a className="about-channel-link" href="https://www.itv.com/">ITVx</a> (ITV)</li>
        <li><a className="about-channel-link" href="https://www.channel4.com/">all4</a> (Channel 4)</li>
        <li><a className="about-channel-link" href="https://www.channel5.com/">My 5</a> (Channel 5)</li>
      </ul>
      <p>To solve the second problem I thought that choosing a film would be easier if one had some indication of its quality. So where possible, the app tries to provide <b>data (from IMDb)</b> for every available film.</p>
      <p>The app also has other features which greatly help choosing a film to watch. Among these are the ability to filter films and a search function. For more details click on the features tab.</p>
    </div>
  )
}

function Sharing() {
  return (
    <div>
      <p>This small web app was created to <i>help me</i> choose a film to watch. However, as I've added more features and as the cost of living continues to rise -  with many people deciding to cut back on non-essentials such as streaming services - I have decided to publically share this app as <i>others may find it useful</i>.</p>
    </div>
  )
}


function DataPolicy() {
  return (
    <div>
      <p>There are <u>no adverts</u> on this app, it <u>does not ask for your email address</u> and <u>stores no cookies</u>.</p>
      <p>The only data saved is the user's short listed films. This is so that they can be reloaded when the user revisit's the site. This data is stored locally (on the user's own device) and is not sent to a server.</p>
    </div>
  )
}
