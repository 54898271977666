import '../css/PageInfoItem.css'

export default function PageInfoItem({title, body}) {
  return (
    <div className='page-info-item m-2'>
      <div className="row">
        <div className="col-sm-4">
          <div className='d-flex h-100 align-items-center justify-content-center'>
            <h2 className='text-center page-info-item-title p-2'>{title}</h2>
          </div>
        </div>
        <div className="col-sm-8 page-info-item-body p-2">
          {body}
        </div>
      </div>
    </div>
  )
}