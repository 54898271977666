import Form from 'react-bootstrap/Form';

function PaginatorSwitch({value, set}) {
  
  const handleClick = e => {
    set(prevState => {
      const updatedValues = { fixedPaginator : !value}
      return {...prevState, ...updatedValues};
    });
  }
  return (
    <Form>
      <Form.Check 
        type="switch"
        id="custom-switch"
        label="Sticky Paginator"
        onChange={handleClick}
        checked={value}
      />
      <Form.Text>
        Always see the Paginator at the bottom of the page.
      </Form.Text>
    </Form>
  );
}

export default PaginatorSwitch